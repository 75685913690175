import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    M.updateTextFields();
    this.initiateInstanceCollapsible();
    this.initiateInstanceChoicesJs();
    this.initiateInstanceSlideOutMenu();
    this.initiateInstanceDropdown();
  }

  disconnect() {
    this.destroyInstanceCollapsible();
    this.destroyInstanceChoicesJs();
    this.destroyInstanceSlideOutMenu();
    this.destroyInstanceDropdown();
  }

  initiateInstanceCollapsible() {
    this.collapsibleInstanceList = [];
    this.collapsible = document.querySelectorAll('.collapsible');

    if (this.collapsible) { this.collapsible.forEach((element, index, array) => {
        this.collapsibleInstanceList.push(M.Collapsible.init(element, {
          inDuration: 450,
          outDuration: 450
        }));
      }, this);
    };
  }
  destroyInstanceCollapsible() {
    if (this.collapsibleInstanceList.length > 0) {
      this.collapsibleInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceChoicesJs() {
    this.choiceInstanceList = [];
    this.choiceSelect = document.querySelectorAll('.js-choice');

    if (this.choiceSelect) { this.choiceSelect.forEach((element, index, array) => {
        let options = { shouldSort: false };
        if (element.multiple) options.removeItemButton = true;
        this.choiceInstanceList.push(new Choices(element, options));
      }, this);
    };
  }
  destroyInstanceChoicesJs() {
    if (this.choiceInstanceList.length > 0) {
      this.choiceInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceSlideOutMenu() {
    this.slidOutMenu  = document.querySelector('#slide-out');
    if (this.slidOutMenu) { this.slideOutMenuInstance = new M.Sidenav(this.slidOutMenu); }
  }
  destroyInstanceSlideOutMenu() {
    if (this.slideOutMenuInstance) this.slideOutMenuInstance.destroy();
  }

  initiateInstanceDropdown() {
    this.dropdownInstanceList = [];
    this.dropdown = document.querySelectorAll('.dropdown-trigger')

    if (this.dropdown) { this.dropdown.forEach((element, index, array) => {
        this.dropdownInstanceList.push(M.Dropdown.init(element, {}));
      }, this);
    };
  }
  destroyInstanceDropdown() {
    if (this.dropdownInstanceList.length > 0) {
      this.dropdownInstanceList.forEach((element) => { element.destroy(); });
    };
  }

}
