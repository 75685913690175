import { Controller } from 'stimulus'
import Rails from '@rails/ujs';
import { flatten } from 'q-flat';

export default class extends Controller {
  static targets = [ 'url', 'btn' ]

  copyShortLink(e) {
    navigator.clipboard.writeText(e.currentTarget.dataset.short_link)
    M.toast({ html: e.currentTarget.dataset.copied,
                    classes: "flash_notice",
                    displayLength: 2500})
  }

  createAnonymous() {
    const params = flatten({
      shortener: { content: this.urlTarget.value }
    });

    Rails.ajax({
      context: this,
      type: 'post',
      dataType: 'json',
      url: '/en/home/shorteners',
      data: new URLSearchParams(params).toString(),
      success: function (shortener) {
        this.context.afterCreateAnonymous(shortener)
        this.context.downloadAnonymous(shortener.id)
      },
      error: function (shortener) {
        M.toast({ html: shortener['content'].join(', ').trim(),
                  classes: "flash_alert",
                  displayLength: 2500})
      },
    });
  }

  afterCreateAnonymous(shortener) {
    this.urlTarget.value = `friqr.com/${shortener.link}`
    this.urlTarget.setAttribute('data-action', 'keyup->shortener#editAfterCreateAnonymous')

    this.btnTarget.children[0].innerHTML = 'content_copy'
    this.btnTarget.setAttribute('data-action', 'click->shortener#copyShortLink')
    this.btnTarget.setAttribute('data-short_link', `friqr.com/${shortener.link}`)
  }

  editAfterCreateAnonymous() {
    if (this.urlTarget.value != this.btnTarget.attributes['data-short_link'].value) {
      this.btnTarget.children[0].innerHTML = 'check'
      this.btnTarget.setAttribute('data-action', 'click->shortener#createAnonymous')
      this.btnTarget.removeAttribute('data-short_link')
    }
  }

  downloadAnonymous(id) {
    const anchor = document.createElement('a');
    anchor.href = `/en/shorteners/${id}/download`;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  chartFilter(event) {
    document.querySelector('.chart_filter.active').classList.toggle('active')
    event.currentTarget.classList.toggle('active')

    const params = flatten({
      locale: document.getElementById('shorteners').dataset.locale,
      id: document.getElementById('shorteners').dataset.shortenerId,
      chart: event.currentTarget.dataset.chart
    });

    Rails.ajax({
      context: this,
      type: 'get',
      dataType: 'json',
      url: `/${params.locale}/shorteners/${params.id}`,
      data: new URLSearchParams(params).toString(),
      success: function (content) {
        Chartkick.charts['line_chart'].updateData(content.data)
      },
      error: function () {},
    });
  }
}
